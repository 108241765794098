<template>
  <!-- 百货订单商品详情 -->
  <div>
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <!-- 购买客户信息 -->
        <div class="pad-container">
          <div class="fs-20">购买客户信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">客户昵称：</div>
            <div class="wid-form">{{ clientInfo.nickname }}</div>
            <div class="form-input-100 tl-r">客户ID：</div>
            <div class="wid-form">{{ clientInfo.id }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">手机号：</div>
            <div class="wid-form">{{ clientInfo.phone }}</div>
            <div class="form-input-100 tl-r">会员等级：</div>
            <div class="wid-form">
              {{
                memberLevel == 18
                  ? "金卡会员"
                  : memberLevel == 20
                  ? "钻石会员"
                  : memberLevel == 21
                  ? "新会员金卡"
                  : memberLevel == 23
                  ? "铂卡会员"
                  : "普通"
              }}
            </div>
          </div>
        </div>
        <!-- 商品信息 -->
        <div class="pad-container mt-24">
          <div class="fs-20">商品信息</div>
          <el-table :data="details.list">
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.goodsUrl)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="原价" prop="goodMarkPrice">
              <template slot-scope="scope">
                <span>￥{{ scope.row.goodMarkPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="实付价" prop="goodsPrice">
              <template slot-scope="scope">
                <span>￥{{ scope.row.goodsPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="goodsNum"></el-table-column>
            <el-table-column
              label="核销状态"
              prop="checktype"
              v-if="details.status == 3 && details.deliveryType == 0"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.checktype == 1">未核销</span>
                <span v-else>已核销</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="details.status == 3 && details.deliveryType == 0"
              label="核销人员"
              prop="salespersonName"
            ></el-table-column>
            <el-table-column
              v-if="details.status == 3 && details.deliveryType == 0"
              label="核销时间"
              prop="photoTime"
            ></el-table-column>
          </el-table>
        </div>
        <!-- 订单信息 -->
        <div class="pad-container mt-24">
          <div class="fs-20">订单信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">订单号：</div>
              <div class="wid-form">{{ details.orderNumber }}</div>
            </div>
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">订单状态：</div>
              <div class="wid-form">{{ getString(1, details.status) }}</div>
            </div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">订单类型：</div>
              <div class="wid-form">{{ getString(0, details.orderType) }}</div>
            </div>
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">所属门店：</div>
              <div class="wid-form">{{ getStoreString(details.storeId) }}</div>
            </div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">订单备注：</div>
              <div class="wid-form">{{ details.remark || "无" }}</div>
            </div>
            <div class="flex-1 flx-row ali-c">
              <div class="tl-l">创建时间：</div>
              <div class="wid-form">{{ details.createTime }}</div>
            </div>
          </div>
        </div>

        <!-- 支付信息 -->
        <div v-if="details.status > 0">
          <div class="pad-container mt-24">
            <div class="fs-20">支付信息</div>
            <div class="mt-24 c-6 flx-row ali-c flx-wr">
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">支付时间：</div>
                <div class="wid-form">{{ details.payTime }}</div>
              </div>
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">优惠券：</div>
                <div class="wid-form">-￥{{ details.couponAmount || 0 }}</div>
              </div>
            </div>
            <div class="mt-24 c-6 flx-row ali-c flx-wr">
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">订单总价：</div>
                <div class="wid-form">￥{{ details.totalPay || 0 }}</div>
              </div>
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">多买折扣：</div>
                <div class="wid-form">-￥{{ details.discountAmount || 0 }}</div>
              </div>
            </div>
            <div class="mt-24 c-6 flx-row ali-c flx-wr">
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">运费：</div>
                <div class="wid-form">{{ details.freightPrice }}</div>
              </div>
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">可获取积分：</div>
                <div class="wid-form">{{ details.gainIntegral || 0 }}</div>
              </div>
            </div>
            <div class="mt-24 c-6 flx-row ali-c flx-wr">
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">实付金额：</div>
                <div class="wid-form">￥{{ details.actualPay || 0 }}</div>
              </div>
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">支付积分：</div>
                <div class="wid-form">￥{{ details.useIntegral || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 自提或者邮寄  已发货或待自提 配送方式：0.自提 1.邮寄 -->
        <div v-if="details.status != 0 && details.status != 6">
          <!-- 自提 -->
          <div v-if="details.deliveryType != 1">
            <div class="pad-container mt-24">
              <div class="fs-20">自提地址</div>
              <div class="flx-row c-6 mt-24 ali-c">
                {{ store.storeAddress }}
              </div>
            </div>
            <div class="pad-container mt-24">
              <div class="fs-20">
                核销信息
              </div>
              <div class="flx-row c-6 mt-24 ali-c">
                <span>自提码:</span> &nbsp; &nbsp;
                <span>1,{{ details.qrCode }}</span>
                 <img class="qrCode" :src="'https://wechat.lirimall.com/file-service/qrCode?code=1,'+details.qrCode"   />
              </div>
            </div>
          </div>
          <!-- 邮寄 -->
          <div v-else>
            <!-- 收货人信息 -->
            <div class="pad-container mt-24">
              <div class="fs-20">收货人信息</div>
              <div class="mt-24 c-6 flx-row ali-c flx-wr">
                <div class="flex-1 flx-row ali-c">
                  <div class="tl-l">收货人:</div>
                  <div class="wid-form">{{ details.linkman }}</div>
                </div>
                <div class="flex-1 flx-row ali-c">
                  <div class="tl-l">手机号码:</div>
                  <div class="wid-form">{{ details.telephone }}</div>
                </div>
              </div>
              <div class="mt-24 c-6 flx-row ali-c flx-wr">
                <span>收货地址: </span>
                <span>{{ details.address }}</span>
              </div>
            </div>
            <!-- 发货信息 -->
            <div class="pad-container mt-24">
              <div class="fs-20">发货信息</div>
              <div class="mt-24 c-6 flx-row ali-c flx-wr">
                <div class="flex-1 flx-row ali-c">
                  <span>快递单号:</span>
                  <span>{{ details.waybillNumber }}</span>
                </div>
                <div class="flex-1 flx-row ali-c">
                  <span>快递公司:</span>
                  <span>{{ role_form.express }}</span>
                </div>
              </div>
              <div class="mt-24 c-6 flx-row ali-c flx-wr">
                <div class="ali-c">
                  <span>发货时间:</span>
                  <span>{{ details.shipTime }}</span>
                </div>
              </div>
              <div class="tl-r">
                <el-button
                  class="form-button"
                  type="primary"
                  icon="el-icon-question"
                  v-if="details.status == 2 && details.deliveryType == 1"
                  @click="
                    showDialog = true;
                    dialogType = 2;
                    role_form.remark = details.waybillNumber;
                  "
                  >修改物流单号</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 4退款中  5已退款  -1拒绝退款 -->
        <div
          v-if="
            details.status == 4 || details.status == 5 || details.status == -1
          "
        >
          <div class="pad-container mt-24">
            <div class="fs-20">退款信息</div>
            <div class="flx-row c-6">
              <div class="flx-col flex-1">
                <div class="mt-24">
                  <span>退款流水号:</span>&nbsp;&nbsp;
                  <span>{{ details.refundNumber }}</span>
                </div>
                 <div class="mt-24">
                  <!-- 退款审核状态，0.待审核 1.运营人员已审核订单 2.财务人员已审核订单 3.运营人员已确认订单 -->
                  <span>退款状态:</span>&nbsp;&nbsp;
                  <span class="mt-24" v-if="details.status == -1">已拒绝</span>
                  <span v-else class="mt-24">{{
                    details.refundStatus == 0
                      ? "待审核"
                      : details.refundStatus == 1
                      ? "运营人员已审核订单"
                      : details.refundStatus == 2
                      ? "财务人员已审核订单"
                      : "运营人员已确认订单"
                  }}</span>
                </div>
              </div>
              <div class="flx-col flex-1">
                <div class="mt-24">
                  <span>退款原因:</span>&nbsp;&nbsp;
                  <span class="mt-24">{{ details.refundReason }}</span>
                </div>
                <div class="mt-24">
                  <span>退款申请时间:</span> &nbsp;&nbsp;
                  <span class="mt-24">{{ details.refundCreateTime }}</span>
                </div>
               
              </div>
            </div>
          </div>
        </div>

        <!-- 物流信息 -->
        <div v-if="details.status == 2 || details.status == 3">
          <div class="pad-container mt-24">
            <div class="fs-20">物流信息</div>
            <div class="fs-16 mt-20">顺丰快递</div>
            <div class="mt-20 c-6 flx-row ali-c flx-wr">
              <div class="flex-1 flx-row ali-c">
                <div class="tl-l">运单号：</div>
                <div class="wid-form">{{ details.waybillNumber }}</div>
              </div>
             
            </div>
             <div style="min-height: 300px;margin-top:20px;">
                <el-steps direction="vertical" :active="1" :space= "80" finish-status="success">
                  
                  <el-step
                  v-for="(item,index) in routeList"
                  :key="index"
                    :title="item.acceptAddress+'  '+item.remark"
                    :description="item.acceptTime"
                  ></el-step>
                </el-steps>
              </div>
          </div>
        </div>

        <!-- 按钮操作 -->
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            class="form-button"
            type="primary"
            v-if="details.status == 1 && details.deliveryType==1"
            @click="openDialog(1)"
            >确认发货</el-button
          >
          <el-button class="form-button" @click="$router.go(-1)"
            >返回</el-button
          >
        </div>
      </el-col>
    </el-row>

    <!-- 确认发货弹窗 和 修改物流单号 弹窗 -->
    <el-dialog
      :visible.sync="showDialog"
      width="680px"
      :title="dialogType == 1 ? '订单发货' : '修改物流单号'"
    >
      <el-form :model="role_form" label-width="80px">
        <el-form-item label="收件人">
          <div class="c-6 pb-10">收货人:&nbsp; &nbsp;{{ details.linkman }}</div>
          <div class="c-6 pb-10">
            手机号:&nbsp;&nbsp;{{ details.telephone }}
          </div>
          <div class="c-6 pb-10">地址:&nbsp;&nbsp;{{ details.address }}</div>
        </el-form-item>
        <el-form-item label="快递公司">
          <el-select
            v-model="role_form.express"
            placeholder="请选择快递公司"
            class="form-input"
          >
            <el-option
              v-for="(item, index) in roleTypeList"
              :key="index"
              :label="item.role"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input
            type="input"
            :rows="4"
            v-model="role_form.remark"
            placeholder="请输入快递单号"
            class="form-input"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {
  getOrderDetail,
  confirmSendOut,
  updateShip,
  getLogisticsInformation
} from "@/api/transactions/order/orders_department";
import { getStoreDetail } from "@/api/service_center/stores"; //根据门店id获取门店列表
export default {
  data() {
    return {
      pubId: null,
      details: {},
      clientInfo: {},
      dialogType: 1, //弹窗类型  1为确认发货  2为修改物流单号
      // 订单状态与门店列表
      order_status: [
        {
          id: -1,
          title: "退款失败"
        },
        {
          id: 0,
          title: "未支付"
        },
        {
          id: 1,
          title: "已支付"
        },
        {
          id: 2,
          title: "已发货"
        },
        {
          id: 3,
          title: "已完成"
        },
        {
          id: 4,
          title: "退款中"
        },
        {
          id: 5,
          title: "已退款"
        },
        {
          id: 6,
          title: "已失效"
        },

        {
          id: 15,
          title: "拼团中"
        }
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易"
        },
        {
          id: 2,
          title: "积分活动"
        },
        {
          id: 3,
          title: "砍价活动"
        },
        {
          id: 6,
          title: "多人拼团"
        },
        {
          id: 7,
          title: "限时抢购"
        },
        {
          id: 8,
          title: "分享集卡"
        }
      ],
      store_list: [],
      memberLevel: null,
      showDialog: false, //确认发货弹窗
      role_form: {
        express: "顺丰速运", //快递公司
        remark: ""
      },
      roleTypeList: [
        {
          value: "顺丰速运",
          label: "顺丰速运"
        }
      ], //快递公司
      store: {
        storePhone: "",
        storeAddress: ""
      },
      routeList: []
    };
  },
  mounted() {
    this.pubId = this.$route.query.id;
    this.memberLevel = this.$route.query.member;
    this.initData();
    this.getStores();
  },
  methods: {
    initData() {
      getOrderDetail(this.pubId).then(res => {
        this.clientInfo = res.data.body.lrUserBaseInfo;
        // console.log(res.data.body);
        this.details = res.data.body;
        if (res.data.body.deliveryType == 0) {
          //配送方式：0.自提 1.邮寄
          getStoreDetail(res.data.body.storeId).then(res => {
            // console.log("res", res.data.body);
            this.store.storePhone = res.data.body.shopStore.storePhone;
            this.store.storeAddress = res.data.body.shopStore.storeAddress;
          });
        }
        if (
          (res.data.body.status == 2 || res.data.body.status == 3) &&
          res.data.body.waybillNumber
        ) {
          let params = {
            trackingNumber: [],
            trackingType: 1 //1:根据顺丰运单号查询
          };
          params.trackingNumber.push(res.data.body.waybillNumber);
          getLogisticsInformation(
            params.trackingNumber,
            params.trackingType
          ).then(res => {
            // console.log(JSON.parse(res.data.body.apiResultData));
            this.routeList = JSON.parse(
              res.data.body.apiResultData
            ).msgData.routeResps[0].routes;
          });
        }
      });
    },
    getStoreString(e) {
      let temp = "";
      this.store_list.forEach(ele => {
        if (ele.id == e) temp = ele.storeName;
      });
      return temp;
    },
    // 获取门店列表
    getStores() {
      getStoreList().then(res => {
        this.store_list = res.data.body.list;
      });
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    //打开弹窗
    openDialog(type) {
      this.showDialog = true;
      this.dialogType = type;
      //弹窗类型  1为确认发货  2为修改物流单号
      if (type == 1) {
      } else if (type == 2) {
        this.role_form.remark = this.details.waybillNumber;
      }
    },
    //弹窗  点击确认
    confirmSubmit() {
      // console.log("dialogType", this.dialogType);
      let params = {
        id: this.details.id,
        waybillNumber: this.role_form.remark
      };
      if (this.dialogType == 1) {
        if (!this.role_form.remark) {
          this.$message({
            message: "请填写快递单号",
            type: "warning"
          });
          return;
        }

        confirmSendOut(params).then(res => {
          this.$message({
            message: "发货成功",
            type: "success"
          });
          this.initData();
          this.showDialog = false;
        });
      } else if (this.dialogType == 2) {
        //修改物流单号
        updateShip(params).then(res => {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.initData();
          this.showDialog = false;
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wid-form {
  width: 300px;
}
.qrCode {
  width: 100px;
  height: 100px;
}
</style>
