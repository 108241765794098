import request from '@/utils/request'

//获取停车列表
export function getparkList () {
    return request({
        url: '/customer-service/parkingManage/parkingCode',
        method: 'post',
    })
}

//根据门店id获取门店列表
//http://${BASE_URL}/goods-service/store/{id}
export function getStoreDetail (id) {
    return request({
        url:"/small-inteface/goods-service/store/" + id,
        method: 'get'
    })
}

//新增、编辑提交门店数据
export function subStoreForm (data, type){  //type 1 编辑 0 新增
    return request({
        url: "/small-inteface/goods-service/store",
        method: type ? "put" : "post",
        data
    })
}

//获取门店列表
export function getStoreList (params) {
    return request({
        url: "/small-inteface/goods-service/store/listOfPage",
        method: 'get',
        params
    })
}

//上下架门店
export function upDownShelfStore (id, type) { //type 1 上架 0 下架
    return request({
        url: "/small-inteface/goods-service/store/" + id,
        method: type ? "put" : "delete"
    })
}

//删除门店
export function deleteStore (id) { //type 1 上架 0 下架
    return request({
        url: "/small-inteface/goods-service/store/deleteStore/" + id,
        method:  'delete'
    })
}